<template>
  <VSheet>
    <div class="print__wrapper" style="overflow:hidden">
      <div class="header">
        <VContainer>
          <VRow aling="center">
            <img src="@/assets/images/logo-dark.svg" alt="" />
          </VRow>
        </VContainer>
      </div>
      <VContainer>
        <VRow class="my-5 d-print-none ">
          <!-- <VBtn text color="primary" @click="onClose"
              ><VIcon left>mdi-arrow-left</VIcon>Назад</VBtn
            > -->
        </VRow>
        <VRow no-gutters justify="space-between" align="center">
          <VCol>
            <h2 class="font-weight-medium">
              {{ $t("printDialog.more_about_supply") }} : <b>{{ this.supply.number }}</b>
            </h2>
          </VCol>
          <div>
            <VSubheader>
              {{ DateToText }}
            </VSubheader>
          </div>
          <div class="mt-1 d-print-none aling-center d-flex">
            <VBtn color="primary" @click="printPage">
              <VIcon left>mdi-printer</VIcon>
              {{ $t("btn.print") }}
            </VBtn>
          </div>
        </VRow>
        <VRow class="my-5">
          <VCol cols="12" xl="6" md="6" sm="6">
            <VCardSubtitle>{{ $t("printDialog.clientData") }}</VCardSubtitle>

            <VSimpleTable class="my-table supply" dense>
              <tbody>
                <tr>
                  <td>{{ `${$t("form.name")} ${$t("form.surname")}` }}</td>
                  <td>{{ `${this.user.name || "--"} ${this.user.surname || "--"}` }}</td>
                </tr>
                <tr>
                  <td>{{ $t("table.phone") }}</td>
                  <td>{{ this.user.phone | VMask("+38 (###) ##-##-###") }}</td>
                </tr>
              </tbody>
            </VSimpleTable>
          </VCol>
        </VRow>
        <VCardSubtitle>{{ $t("printDialog.item_in_supply") }}</VCardSubtitle>
        <div v-if="items.length > 0 && !loading">
          <transition name="fade-print">
            <VSimpleTable class="my-table supply" dense v-if="items.length > 0 && !loading">
              <template slot:default>
                <thead>
                  <tr>
                    <th class="text-center">#</th>
                    <th>{{ $t("table.name") }}</th>
                    <th>{{ $t("table.bar_code") }}</th>
                    <th>{{ $t("table.option") }}</th>
                    <th>
                      {{ $t("table.ns_code") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.uuid">
                    <th class="text-center">{{ index + 1 }}</th>
                    <td>
                      {{ item.product_details.name || "--" }}
                    </td>
                    <td>
                      {{ item.product_details.bar_code || "--" }}
                    </td>
                    <td>
                      {{ item.option_details.name || "--" }}
                    </td>
                    <td>
                      {{ item.ns_code || "--" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </VSimpleTable>
            <div class="loader__wrapper" v-if="loading">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="3"
                color="primary lighten-1"
              ></v-progress-circular>
            </div>
          </transition>
        </div>
        <transition name="fade-print" v-else>
          <div class="loader__wrapper" v-if="loading">
            <v-progress-circular
              indeterminate
              :size="30"
              :width="3"
              color="primary lighten-1"
            ></v-progress-circular>
          </div>
          <div v-else class="d-flex justify-content-center align-center" style="height:20vh">
            {{ $t("table.noData") }}
          </div>
        </transition>
      </VContainer>
    </div>
  </VSheet>
</template>

<script>
import notifications from "@/mixins/notifications";
import depotService from "../../services/request/depot/depotService";
import user from "../../mixins/user";
import { mapGetters } from "vuex";
import moment from "moment";
import supplyService from "../../services/request/supply/supplyService";

export default {
  mixins: [notifications, user],
  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    items: [],
    loading: true,
    supply: [],
    user: []
  }),

  mounted() {
    // console.log(this.$route.params);
    this.getSupply();
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    DateToText() {
      if (this.supply.time_created) {
        return moment(this.$route.params.time_created)
          .locale(this.currentLanguage.key)
          .format("LL");
      } else {
        return "--";
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.user = [];
    },
    async printPage() {
      window.print();
    },
    async getSupply() {
      try {
        this.loading = true;
        this.user = this.supply;
        this.supply = await supplyService.getSupplyItem(this.$route.params.id);
        this.user = this.supply.user_details;
        this.getItems();
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e);
      }
    },
    async getItems() {
      try {
        this.loading = true;
        switch (this.$route.params.type) {
          case "confirmed":
            this.items = await depotService.getMyСonfirmedProducts(this.$route.params.id, true);
            break;
          case "in":
            this.items = await depotService.getMyProducts({
              uuid_cell: this.supply.uuid_cell
            });
            break;
          case "returned":
            this.items = await supplyService.returnItemList({
              uuid: this.supply.uuid,
              details: true
            });
            break;
          case "supply":
            this.items = await supplyService.getCompletedItemDetailsByDeliveryUuid(
              this.supply.uuid,
              {
                details: true
              }
            );
            break;
          default:
            console.log(1);
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e);
      }
    }
  }
};
</script>

<style lang="scss">
body,
html {
  overflow: unset !important;
}
.header {
  width: 100%;
  background: darken(white, 5);
  border-bottom: 1px solid darken(white, 10);
  padding: 10px 0;
  height: auto;
}
.print__wrapper {
  background: #ffff;
}

@media print {
  .my-table {
    .v-data-table__wrapper {
      overflow-x: hidden !important;
    }
  }
  .container {
    padding: 10px 10px !important;
  }
  .header {
    padding: 10px;
  }
}
.fade-print-leave-active {
  transition: opacity 0.5s;
}
.fade-print-leave-to {
  opacity: 0;
}
.loader__wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 80px;
  z-index: 100;
  background: white;
  height: calc(100vh - 397px);
  width: 100%;
  left: 0;
  top: 397px;
}
</style>
