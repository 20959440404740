<template>
  <items-list-print-dialog />
</template>

<script>
import ItemsListPrintDialog from '../components/dialog/ItemsListPrintDialog.vue';

export default {
  name: 'Supply',
  components: {
    ItemsListPrintDialog,
  },
};
</script>

<style scoped>

</style>
